import { WebChatWrapper } from './components';
import { API_BASE_URL, API_KEY } from './constants';

export const App = () => {
  return (
    <WebChatWrapper
      apiKey={API_KEY}
      apiUrl={API_BASE_URL}
    />
  );
}

export default App;
