import axios from 'axios';
import { API_TALANTSIFT_URL, API_BASE_URL } from './constants';

class BotService {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: API_BASE_URL,
    });
  }

  async addBotActivity(botActivity) {
    try {
      const response = await this.axiosInstance.post('/api/BotMessage/AddBotMessages', botActivity);
      if (response?.data) {
        localStorage.setItem('Bot', JSON.stringify(response?.data));
      }
    } catch (error) {
      console.error('Error adding bot activity:', error);
    }
  }

  async addTeamsMessages(botActivity) {
    try {
      const response = await this.axiosInstance.post('/api/BotMessage/AddTeamsMessages', botActivity);
      if (response?.data) {
        localStorage.setItem('Bot', JSON.stringify(response?.data));
      }
    } catch (error) {
      console.error('Error adding teams messages:', error);
    }
  }

  async getQueueNumber(showconversationId) {
    try {
      const response = await this.axiosInstance.get(`${API_TALANTSIFT_URL}/api/BotMessage/GetConnectToAgentDetailsCount/${encodeURIComponent(showconversationId)}`);
      if (response?.data) {
        localStorage.setItem('Bot', JSON.stringify(response?.data));
      }
      return response;
    } catch (error) {
      console.error('Error fetching queue number:', error);
    }
  }

  async getCalendarBookingForUser(showconversationId) {
    try {
      const response = await this.axiosInstance.get(`${API_TALANTSIFT_URL}/api/BotMessage/GetCalendarBookingforuser/${encodeURIComponent(showconversationId)}`);
      if (response?.data) {
        localStorage.setItem('Bot', JSON.stringify(response?.data));
      }
      return response;
    } catch (error) {
      console.error('Error fetching calendar booking for user:', error);
    }
  }

  async getAvailableSlotsForUser(startDate) {
    try {
      const response = await this.axiosInstance.get(`${API_TALANTSIFT_URL}/api/Booking/GetAvailableSlotsforuser/${encodeURIComponent(startDate)}`);
      if (response?.data) {
        localStorage.setItem('Bot', JSON.stringify(response?.data));
      }
      return response;
    } catch (error) {
      console.error('Error fetching available slots for user:', error);
    }
  }

  async createMeetingWithAgent(bookingUserData) {
    try {
      const response = await this.axiosInstance.post(`${API_TALANTSIFT_URL}/api/Booking/CreateMeetingwithAgent/`, bookingUserData);
      if (response?.data) {
        localStorage.setItem('Bot', JSON.stringify(response?.data));
      }
      return response;
    } catch (error) {
      console.error('Error creating meeting with agent:', error);
    }
  }
}

export default new BotService();
