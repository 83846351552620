import React, { useState, useEffect, useMemo } from 'react';
import ReactWebChat, {
  createStore,
  createDirectLine,
} from 'botframework-webchat';
import { BOT_LOADER_PLACEHOLDER_PNG, BOT_AVATAR_PNG } from '../../constants';
import BotService from '../../botservice';
import './bot.css';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export const WebChat = ({
  onFetchToken,
  setNewMessage,
  token,
  userId,
  directLineSessionStorageKey,
}) => {
  const directLine = useMemo(() => createDirectLine({ token }), [token]);
  const [hideButton, sethideButton] = useState(true);
  const [hideTextBox, setHideTextBox] = useState(true);
  const [showNewChatLink, setShowNewChatLink] = useState(false);
  const [showconversationId, setshowconversationId] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showbookingUser, setshowbookingUser] = useState(null);

  const store = useMemo(
    () =>
      createStore({}, ({ dispatch }) => (next) => (action) => {
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
              name: 'webchat/join',
              value: {
                language: window.navigator.language,
              },
            },
          });
        } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          if (
            action.payload.activity.type === 'event' &&
            action.payload.activity.name === 'updateusername'
          ) {
            return;
          }

          if (
            action.payload &&
            action.payload.activity &&
            action.payload.activity.type === 'message'
          ) {
            setshowconversationId(action.payload.activity.conversation.id);
          }

          if (action.payload.activity.from.role === 'bot') {
            if (
              action.payload.activity.text === 'Please upload resume' ||
              action.payload.activity.text ===
                'Unsupported file format. Please upload a pdf/doc/docx/jpeg/png file.' ||
              action.payload.activity.text === 'Please select only one file' ||
              action.payload.activity.text ===
                'Please kindly upload the certificate here' ||
              action.payload.activity.text ===
                'Please upload the documents here' ||
              action.payload.activity.text ===
                'Please upload your CV to proceed further' ||
              action.payload.activity.text ===
                "Please upload your Father's Birth Certificate." ||
              action.payload.activity.text ===
                'Please upload your University Transcripts'
            ) {
              sethideButton(false);
            } else {
              sethideButton(true);
            }
            if (
              action.payload.activity.text ===
              'To get started, please help me with your name.'
            ) {
              setHideTextBox(false);
            }

            if (action.payload.activity.text === 'Your chat has ended.') {
              setShowNewChatLink(false);
              setHideTextBox(true);
            }

            //Demo Flow
            if (
              action.payload.activity.text === 'To start a new chat, click here'
            ) {
              setShowNewChatLink(true);
              setHideTextBox(true);
            }
            // demo Flow
            if (
              action.payload.activity.text ===
                'To start a new chat, click here' ||
              action.payload.activity.text
                .toLowerCase()
                .includes('Your conversation with'.toLowerCase())
            ) {
              setShowNewChatLink(true);
              setHideTextBox(true);
            }

            if (
              action.payload.activity.text ===
              'The recruitment consultants you should speak to are currently offline as this conversation is taking place outside of office hours. However, you can still schedule a meeting with them.'
            ) {
              setShowCalendar(true);
              setHideTextBox(true);
            }
          }

          if (action.payload.activity.type === 'message') {
            setNewMessage(true);
            const messageContainer = document.querySelector(
              '.talentsift-web-chat-wrapper .bot [role="group"] section[role="feed"]',
            );
            if (messageContainer) {
              setTimeout(() => {
                const lastChild = messageContainer.lastChild;
                if (lastChild) {
                  lastChild.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
              }, 1000);
            }
          }
        }

        return next(action);
      }),
    [setNewMessage],
  );

  useEffect(() => {
    onFetchToken();
  }, []);

  const cardActionMiddleware =
    () =>
    (next) =>
    async ({ cardAction }) => {
      if (
        cardAction?.type === 'openUrl' &&
        cardAction.title.toLowerCase() === 'connect to agent'
      ) {
        //onAgentRequest();
        return;
      }
      if (
        cardAction?.value?.toLowerCase().includes('start a new conversation')
      ) {
        window.location.href = window.location.href;

        localStorage.clear(directLineSessionStorageKey);
        onFetchToken();
      }
      return next({ cardAction });
    };

  const activityMiddleware = () => (next) => (card) => {
    const { activity } = card || {};
    if (activity?.attachments && activity?.attachments?.length > 0) {
      const attachments = activity.attachments.map((attachment, index) => (
        <div key={index}>
          <a
            href={attachment?.contentUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {attachment?.name}
          </a>
        </div>
      ));
      return (
        <div className="filetxt">
          {attachments}
          {next(card)}
        </div>
      );
    }
    if (
      activity.type === 'message' &&
      activity.text === 'To start a new chat, click here' &&
      showNewChatLink
    ) {
      return (
        <div className="newStartConversation">
          <a href="#" onClick={(event) => handleStartNewChat(event)}>
            {activity.text}
          </a>
        </div>
      );
    }

    //Demo Flow
    if (
      activity?.type === 'message' &&
      activity?.text === 'Click here for New Conversation' &&
      showNewChatLink
    ) {
      return (
        <div className="newStartConversation">
          <a href="#" onClick={(event) => handleStartNewChat(event)}>
            {activity?.text}
          </a>
        </div>
      );
    }

    //Demo Flow
    if (
      activity.type === 'message' &&
      showNewChatLink &&
      activity.text.includes('Your conversation with')
    ) {
      return (
        <div>
          <div className="conversationEnded cal-bubble chat-endedw1">
            Your chat has ended.
          </div>

          <div className="newStartConversation mx20">
            <a href="#" onClick={(event) => handleStartNewChat(event)}>
              {'To start a new chat, click here'}
            </a>
          </div>
        </div>
      );
    }
    return next(card);
  };

  const handleStartNewChat = async (event) => {
    event.preventDefault();
    localStorage.clear(directLineSessionStorageKey);
    onFetchToken();
    window.location.reload();
  };

  const [remainingMessage, setremainingMessage] = useState(' ');

  const activityStatusMiddleware = () => {
    let intervalId;
    let intervalSet = false;

    return (next) => (args) => {
      const {
        activity: {
          from: { role },
          text,
        },
      } = args || {};

      const fetchQueueDataWithDelay = async () => {
        try {
          const response = await BotService.getQueueNumber(showconversationId);
          const queueData = response?.data;
          if (queueData > 0) {
            const message = `You have been placed as ${queueData} in the High priority user queue`;
            setremainingMessage(message);
          } else {
            setremainingMessage('');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

      if (role === 'bot') {
        if (
          text ===
          'Your request has been intimated to our agents, please wait till our agent is ready to take up your case.'
        ) {
          if (!intervalSet) {
            setTimeout(() => {
              fetchQueueDataWithDelay(); // Initial fetch
              intervalId = setInterval(fetchQueueDataWithDelay, 60000);
              intervalSet = true;
            }, 20000);
          }

          return (
            <span className="activityStatus__timestampContent">
              {remainingMessage ? remainingMessage : next(args)}
            </span>
          );
        } else if (
          text.toLowerCase().includes('you are now connected to'.toLowerCase())
        ) {
          clearInterval(intervalId);
          intervalSet = false;
          setremainingMessage('');
          return (
            <span className="activityStatus__timestampContent">
              {next(args)}
            </span>
          );
        } else {
          return (
            <span className="activityStatus__timestampContent">
              {next(args)}
            </span>
          );
        }
      } else {
        return (
          <span className="activityStatus__timestampContent">{next(args)}</span>
        );
      }
    };
  };

  //Calender coding for demo instance
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [showSlotTimings, setShowSlotTimings] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(false);
  const [showForm, setshowForm] = useState(false);
  const [showSlot, setshowSlot] = useState(null);
  const [showDate, setshowDate] = useState(null);
  const [showcalendardata, setshowcalendardata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showresponse, setshowresponse] = useState({});
  const [showSucessMessage, setshowSucessMessage] = useState(false);
  const [formattedSelectDated, setformattedSelectDated] = useState(null);

  const CalendarComponent = () => (next) => (card) => {
    const handleDateClick = (value) => {
      const day = value.getDate().toString().padStart(2, '0');
      const month = (value.getMonth() + 1).toString().padStart(2, '0');
      const year = value.getFullYear().toString();
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      const dateString = `${month}-${day}-${year}`;
      const formatMonth = months[value.getMonth()];
      const formatChangedDate = `${day}-${formatMonth}-${year}`;
      // const dateString = `${day}-${month}-${year}`;
      setSelectedDate(dateString);
      setformattedSelectDated(formatChangedDate);
      fetchGetAvailableSlotData(dateString);

      setShowSlotTimings(true);
    };
    const fetchGetAvailableSlotData = async (dateString) => {
      try {
        setLoading(true);
        const responseAvailableSlots =
          await BotService.getAvailableSlotsForUser(dateString);
        setshowcalendardata(responseAvailableSlots?.data);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };
    const handleSlotClick = (slot, index) => {
      setSelectedSlot({ slot, index });
      setConfirmButtonEnabled(true);
    };

    const handleConfirmClick = (slot, selectedDate) => {
      setshowForm(true);
      setShowSlotTimings(false);
      setShowCalendar(false);

      setshowSlot(slot);

      setshowDate(selectedDate);
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        setLoading(true);
        const [startTime, endTime] = showSlot.split('-');
        const [day, month, year] = showDate.split('-');
        const [startHour, startMinute] = startTime.trim().split(':');
        const [endHour, endMinute] = endTime.trim().split(':');
        const formattedStartTime = `${year}-${month.padStart(
          2,
          '0',
        )}-${day.padStart(2, '0')}T${startHour.padStart(
          2,
          '0',
        )}:${startMinute.padStart(2, '0')}:00`;
        const formattedEndTime = `${year}-${month.padStart(
          2,
          '0',
        )}-${day.padStart(2, '0')}T${endHour.padStart(
          2,
          '0',
        )}:${endMinute.padStart(2, '0')}:00`;

        const bookingUserData = {
          name: showbookingUser.userInformation.name,
          email: showbookingUser.userInformation.email,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
        };
        const responseMeetingWithAgent =
          await BotService.createMeetingWithAgent(bookingUserData);
        setshowresponse(responseMeetingWithAgent?.data);
        setshowForm(false);
        setshowSucessMessage(true);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };
    const fetchCalendarData = async (showconversationId) => {
      try {
        const responseCalendarBooking =
          await BotService.getCalendarBookingForUser(showconversationId);
        setshowbookingUser(responseCalendarBooking?.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    const isPastDate = (date) => {
      const today = new Date();
      return date < today;
    };
    const { activity } = card || {};
    if (
      activity.type === 'message' &&
      activity.text ===
        'The recruitment consultants you should speak to are currently offline as this conversation is taking place outside of office hours. However, you can still schedule a meeting with them.' &&
      showCalendar
    ) {
      fetchCalendarData(showconversationId);

      return (
        <div className="calendar-view">
          <div className="cal-bubble">{activity.text}</div>

          <div className="cal-bubble">
            {showCalendar && (
              <Calendar
                onChange={setDate}
                value={date}
                onClickDay={handleDateClick}
                tileDisabled={({ date }) => isPastDate(date)}
              />
            )}
            {showSlotTimings && (
              <div className="timeslot-view">
                <h2>Slot Timings for {formattedSelectDated} (UTC):</h2>
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  showcalendardata &&
                  showcalendardata.length > 0 &&
                  (console.log(showcalendardata),
                  (
                    <ul>
                      {showcalendardata
                        .find((item) => item.date === selectedDate)
                        ?.slotTimings.map((slot, index) => (
                          <li key={index} className="timeslot-list">
                            <button
                              onClick={() => handleSlotClick(slot, index)}
                              className="timeslot-btn"
                            >
                              {slot}
                            </button>
                            {selectedSlot && selectedSlot.index === index && (
                              <button
                                className="time-confirm-btn"
                                onClick={() =>
                                  handleConfirmClick(slot, selectedDate)
                                }
                                disabled={!confirmButtonEnabled}
                              >
                                Confirm
                              </button>
                            )}
                          </li>
                        ))}
                    </ul>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
    if (
      activity.type === 'message' &&
      activity.text ===
        'The recruitment consultants you should speak to are currently offline as this conversation is taking place outside of office hours. However, you can still schedule a meeting with them.' &&
      showForm
    ) {
      return (
        <div className="cal-content">
          <div className="cal-bubble">{activity.text}</div>

          <div className="cal-bubble">
            <form onSubmit={handleSubmit}>
              <span>{formattedSelectDated}</span>
              <span> | </span>
              <span>{showSlot}</span>

              <div className="mb-3 cal-form-name">
                <label className="form-label cal-label">Name</label>
              </div>
              <div className="mb-3 main-input">
                <input
                  type="text"
                  className="form-control cal-input"
                  name="name"
                  value={showbookingUser.userInformation.name}
                />
              </div>
              <div className="mb-3 cal-form-name">
                <label className="form-label cal-label">Email</label>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control cal-input"
                  name="email"
                  value={showbookingUser.userInformation.email}
                />
              </div>

              <div className="mb-3">
                <button
                  type="submit"
                  className="cal-submit-btn"
                  disabled={loading}
                >
                  {loading ? (
                    <img src="images/ca-loader.svg" className="ca-loader" />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    }
    if (
      activity.type === 'message' &&
      activity.text ===
        'The recruitment consultants you should speak to are currently offline as this conversation is taking place outside of office hours. However, you can still schedule a meeting with them.' &&
      showSucessMessage
    ) {
      return (
        <div className="cal-content">
          <div className="cal-bubble">{activity.text}</div>

          <div className="cal-bubble">
            <span>{formattedSelectDated}</span>
            <span> | </span>
            <span>{showSlot}</span>
            <br />
          </div>

          <div className="cal-bubble">
            <span>{showresponse}</span>
          </div>

          <div className="cal-bubble">
            <div className="conversationEnded-form">
              Thank you for your time Demo. I will now close this session
            </div>
          </div>

          <div className="cal-bubble">
            <div className="conversationEnded-form">Until next time!</div>
          </div>

          <div className="cal-bubble">
            <div className="conversationEnded-form">Your chat has ended.</div>
          </div>

          <div className="newStartConversation-form">
            <a href="#" onClick={(event) => handleStartNewChat(event)}>
              {'To start a new chat, click here'}
            </a>
          </div>
        </div>
      );
    }
    return next(card);
  };

  return token ? (
    <ReactWebChat
      className="bot"
      directLine={directLine}
      store={store}
      activityStatusMiddleware={activityStatusMiddleware}
      cardActionMiddleware={cardActionMiddleware}
      activityMiddleware={activityMiddleware}
      attachmentMiddleware={CalendarComponent}
      styleOptions={{
        // Basic styling
        accent: '#279989',
        backgroundColor: '#F6F6F7',
        suggestedActionLayout: 'stacked',
        suggestedActionsStackedLayoutButtonTextWrap: true,
        autoScrollSnapOnActivity: true,
        primaryFont: 'Inter, sans-serif',
        fontSize: '14px',
        fontSizeSmall: '12px',

        // Bubble Bot side
        bubbleBackground: '#DFF0EE',
        bubbleBorderColor: '#DFF0EE',
        bubbleBorderRadius: 7,
        bubbleBorderStyle: 'solid',
        bubbleBorderWidth: 1,
        bubbleTextColor: 'rgba(12, 46, 41, 0.75)',

        // Bubble User side
        bubbleFromUserBackground: '#279989',
        bubbleFromUserBorderColor: '#279989',
        bubbleFromUserBorderRadius: 7,
        bubbleFromUserBorderStyle: 'solid',
        bubbleFromUserBorderWidth: 1,
        bubbleFromUserTextColor: '#ffffff',

        // Avatar
        botAvatarImage: BOT_AVATAR_PNG,
        botAvatarInitials: 'CH',
        hideUploadButton: hideButton,
        hideSendBox: hideTextBox,
        avatarBorderRadius: '50%',
        avatarSize: 40,
      }}
      userID={userId}
    />
  ) : (
    <div className="connect-spinner">
      <div className="content">
        <img
          className="bot-init-loader-logo"
          src={BOT_LOADER_PLACEHOLDER_PNG}
          alt="chat automaiton logo"
        />
        <p>Please wait while we are connecting.</p>
      </div>
    </div>
  );
};
