// export const API_BASE_URL = "https://talentsift-bots-service-qa.azurewebsites.net"; // QA
export const API_BASE_URL = "https://talentsift-bots-service-demo.azurewebsites.net"; // DEMO
// export const API_BASE_URL = "https://talentsift-bots-service.azurewebsites.net"; // CONEXUS

// export const API_TALANTSIFT_URL = "https://talentsift-services-qa.azurewebsites.net"; // QA
export const API_TALANTSIFT_URL = "https://talentsift-services.azurewebsites.net"; // DEMO
// export const API_TALANTSIFT_URL = "https://talentsift-services-conexus.azurewebsites.net"; // CONEXUS

export const API_KEY = "";
export const ACS_BASE_URL = "";
export const BOT_LOADER_PLACEHOLDER_PNG = `assets/chat-automation-logo.png`;
// export const BOT_AVATAR_PNG = `assets/Emma-avatar.jpeg`; // QA
export const BOT_AVATAR_PNG = `assets/Emma-avatar.jpeg`; // DEMO
// export const BOT_AVATAR_PNG = `assets/bot-avatar.png`; // CONEXUS