import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { WebChat } from '..';
import './wrapper.css';

const directLineSessionStorageKey = 'cb-dl';

export const WebChatWrapper = ({ apiUrl, apiKey }) => {
  const [token, setToken] = useState(null);
  const [botUserId, setBotUserId] = useState(null);

  const fetchToken = useCallback(async () => {
    try {
      if (!token) {
        const response = await axios.post(
          `${apiUrl}/api/directline/token`,
          null,
          {
            headers: {
              'X-CAC-Api-Key': apiKey,
            },
          },
        );

        const { token, conversationId, expires_in, userId } =
          response?.data || {};
        const today = new Date();
        const expires_at = new Date(today.getTime() + expires_in * 1000);

        const tokenData = {
          token,
          userId,
          conversationId,
          expires_in,
          expires_at,
        };

        localStorage.setItem(
          directLineSessionStorageKey,
          JSON.stringify(tokenData),
        );

        setToken(token);
        setBotUserId(userId);
      }
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  }, [apiUrl, apiKey, token]);

  useEffect(() => {
    const tokenData = localStorage.getItem(directLineSessionStorageKey);

    if (!tokenData) {
      return;
    }

    const parsedData = JSON.parse(tokenData);
    const isExpired = new Date(parsedData.expires_at) <= new Date();

    if (!isExpired) {
      setToken(parsedData?.token);
      setBotUserId(parsedData?.userId);
    } else {
      localStorage.removeItem(directLineSessionStorageKey);
    }
  }, []);

  return (
    <div className="talentsift-web-chat-wrapper">
       <WebChat
          onFetchToken={fetchToken}
          setNewMessage={() => {}}
          token={token}
          userId={botUserId}
          directLineSessionStorageKey={directLineSessionStorageKey}
        />
    </div>
  );
};
